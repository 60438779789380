// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  $('.ease-btm , .ease-left, .ease-right, .ease-top').attr('data-scroll', '');
  ScrollOut({
    once: true,
    onShown: function (element) {
      if ($(element).is('.ease-order')) {
        $(element)
          .find('.ease-order__item')
          .each(function (i) {
            let $this = $(this);
            $(this).attr('data-scroll', '');
            window.setTimeout(function () {
              $this.attr('data-scroll', 'in');
            }, 300 * i);
          });
      }
    },
  });

  // loadmore posts
  const postsPerPage = $('#ajax-posts').data('pnumber');
  let countPosts = $('#more_posts').data('count');

  /* eslint-disable */

  $('#more_posts').on('mouseover', () => {
    if (!$('.recents__loadmore').hasClass('loading')) {
      let currentCount = $('.recents__item').length;
      let data = {
        action: 'more_post_ajax',
        offset: currentCount,
        postsPerPage: postsPerPage,
        nonce: ajax.nonce,
      };
      $.ajax({
        type: 'POST',
        dataType: 'html',
        url: ajax.url,
        data: data,
        beforeSend: () => {
          $('.recents__loadmore').addClass('loading');
        },
        success: (data) => {
          let $data = $(data);
          if ($data.length) {
            $('#ajax-posts').append($data);
            currentCount = $('.recents__item').length;
            $('.recents__loadmore').removeClass('loading');
            if (currentCount == countPosts) {
              $('#more_posts').hide();
            }
          }
        },
        error: () => {
          console.log('error');
        },
      });
      return false;
    }
    $(this).insertAfter('#ajax-posts');
  });

  /* eslint-enable */

  //author label fix
  $('.recents__author br').remove();

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  // UNDERLINE FEATURE

  (() => {
    const elements = document.querySelectorAll('h2');
    const nameElement = 'last-line-element';
    const nameRow = 'last-line-row';

    const wrapAll = (nodes, wrapper, elem) => {
      const parent = nodes[0].parentNode;
      const { previousSibling } = nodes[0];
      for (
        let i = 0;
        nodes.length - i;
        wrapper.firstChild === nodes[0] && (i += 1)
      ) {
        wrapper.appendChild(nodes[i]);
      }
      if (previousSibling) {
        parent.insertBefore(wrapper, previousSibling.nextSibling);
      } else {
        elem.appendChild(wrapper);
      }
      return wrapper;
    };

    const findLastRow = (elem) => {
      const content = elem.innerText.trim();
      const contentArr = content.split(' ');
      let contentFormatted = '';
      contentArr.forEach((item) => {
        contentFormatted += `<span>${item} </span>`;
      });
      const element = elem;
      element.innerHTML = contentFormatted;

      const childrenSpan = Array.from(elem.getElementsByTagName('span'));
      let top = 0;
      childrenSpan.forEach((item) => {
        const thisTop = item.offsetTop;
        if (thisTop === top) {
          item.classList.add(nameElement);
        } else {
          childrenSpan.forEach((el) => {
            el.classList.remove(nameElement);
          });
          top = thisTop;
          item.classList.add(nameElement);
        }
      });

      const wrapElements = element.querySelectorAll(`.${nameElement}`);
      const wrapper = document.createElement('span');
      wrapper.classList.add(nameRow);
      wrapAll(wrapElements, wrapper, elem);
    };

    const findLastRows = () => {
      elements.forEach((elem) => {
        findLastRow(elem);
      });
    };

    window.addEventListener('resize', () => {
      findLastRows();
    });
    findLastRows();
  })();

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
